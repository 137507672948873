<template>
	<div>
		<p class="text-center">Você pode criar perfis com permissionamentos distintos para vincular colaboradores.</p>
		<div 
			class="border px-4 py-3 mt-3 rounded-lg"
			v-for="profile in profiles"
			:key="profile.id"
		>
			<div class="row justify-content-md-between align-items-center">
				<div class="col mb-4 mb-md-0">
					{{ profile.name.toUpperCase() }}								
				</div>
				<div class="col-auto">
					<div 
						class="d-inline-block form-group mr-4 mb-0 form-check"
						:class="{ 'opacity-50': !profile.account_id }"
					>
						<input 
							type="checkbox" 
							:id="`switch${profile.id}`" 
							v-model="profile.status"
							:disabled="!profile.account_id"
							@click="AlterStatusProfile(profile)"
						>
						<label :for="`switch${profile.id}`">
							{{profile.status == 1 ? 'ATIVO' : 'INATIVO'}}
						</label>
					</div>
					<button 
						class="btn btn-light btn-sm px-3 mr-2"
						:class="{ 'opacity-50': !profile.account_id }"
						:disabled="!profile.account_id"
						@click="Edit(profile)"
					> 
						editar 
					</button>
					<button 
						class="btn btn-light btn-sm px-3" 
						@click="Clone(profile)"
					> 
						clonar 
					</button>
				</div>
			</div>
		</div>

		<button 
			class="btn btn-primary px-5 mt-4"
			@click="New()"
		>
			criar
		</button>
	    <div 
	    	class="modal fade" 
	    	id="modal-profile"
	    >
	        <div class="modal-dialog modal-lg modal-dialog-centered">
	            <div class="modal-content">
	            	<div class="modal-header px-5 pt-4">
			    		<h4 class="font-20 m-0">{{ profile.id ? 'Editar' : 'Criar'}} perfil</h4>
				        <button 
				        	type="button" 
				        	class="position-absolute btn btn-light p-3 ps-r-0 ps-t-0 m-3" data-dismiss="modal"
				        >
				         	<i class="far fa-times mx-1"></i>
				        </button>
			    	</div>
		            <div class="modal-body">
		                <div class="row p-4">
							<div class="col-12">
								<div class="form-group">
									<label>Nome do perfil</label>
									<input 
										type="text" 
										class="form-control" 
										v-model="profile.name" 
									>
								</div>
								<div class="table-responsive mt-4">
									<table class="table table-hover">
										<thead>
											<tr>
												<th scope="col" class="h6">Local</th>
												<th scope="col" class="h6">Descrição</th>
												<th scope="col" class="h6"></th>
											</tr>
										</thead>
										<tbody>
											<tr 
												v-for="role in roles"
												:key="role.id"
											>
												
												<td class="text-dark">{{role.name}}</td>
												<td class="text-dark">{{role.description}}</td>
												<th scope="col">
													<div class="d-inline-block form-group mr-4 mb-0 form-check">
														<input 
															type="checkbox" 
															:id="`check_role_${role.id}`" 
															:value="role.id"
															v-model="profile.roles"
														>
														<label :for="`check_role_${role.id}`">
														</label>	
													</div>						
												</th>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
		                </div>
		            	<div class="d-flex justify-content-between p-4">
			                <button 
			                	type="button" 
			                	class="btn btn-light p-3 px-4" 
			                	data-dismiss="modal" 
			                >	
			            		cancelar
			            	</button>
			                <button 
			                	type="button" 
			                	class="btn btn-primary p-3 px-4" 
			                	@click="Save"
			                >
			            		salvar
			            	</button>
			            </div>					  
		            </div>
	            </div>
	        </div>
	    </div>  
	</div>
</template>

<script>
	const _ = require('lodash')

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
	export default {

		name: 'Profiles',
		props: 		{
			organization_id: 	{
				type: 		[String, Number],
				required: 	true,
				default: 	null
			}
		},
		data () {
			return {
				profiles: 			[],
				roles: 				[],
				profile: 			{
					id: 		null,
					name: 		null,
					roles: 		[]
				},
				profile_opt: 		'exists'
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading'
			]),
			GetProfiles()
			{
				this.StartLoading()

				return window.api.call('post', '/get-profiles', {
						account_id: 	this.organization_id,
						origin:			'erp'
					})
					.then(({data}) => {
						if(data.response)
						{
							this.profiles 	= data.profiles
						}
					})	
					.finally(() => {
						this.FinishLoading()
					})
			},
			GetRoles()
			{
				this.StartLoading()

				return window.api.call('get','/get-roles')
					.then(({data}) => {
						if(data.response)
						{
							this.roles 	= _.orderBy(data.roles, 'description', 'asc')
						}
					})
					.finally(() => {
						this.FinishLoading()
					})			
			},
			AlterStatusProfile(profile)
			{
				const vm 	= this

				Swal.fire({
					title: 'Confirmar Ação',
					text: `Deseja realmente ${ profile.status ? 'ativar' : 'inativar' }`,
					icon: 'info',
					showCancelButton: true,
					confirmButtonColor: '#009e92',
					cancelButtonColor: '#d33',
					confirmButtonText: 'sim',
					cancelButtonText: 'não'
				}).then((result) => {
					if (result.value) 
					{
						vm.StartLoading()

						window.jQuery("input").prop('disabled', true)

						window.api.call('post','/disable-profile',{
								id: 			profile.id,
								account_id: 	vm.organization_id
							})
							.then(({data}) => {

								window.jQuery("input").prop('disabled', false)

								vm.LoadProfiles()

							})
							.catch(()=>{

								window.jQuery("input").prop('disabled', false)

								vm.SwitchClass(profile)
							})
							.finally(() => {
								vm.FinishLoading()
							})

					}else{

						vm.SwitchClass(profile)
					}
				})
			},
			Save()
			{
				const vm 		= this

				vm.StartLoading()

	            return window.api.call('post','/save-profile',{
		                account_id: 	vm.organization_id,
		               	...this.profile 
		            })
		            .then( async ({data}) => {      
		            		
						if(data.response)
		  				{
		  					Swal.fire({
		  						icon: 		'success',
		  						title: 		'Perfil alterado com sucesso',
		  						toast: 		true,
		  						position: 	'top',
		  						timer: 		3000
		  					})

		  					await Promise.all([
		  							this.GetProfiles()
		  						])
		  						.finally(() => {
		  							this.profile.name 	= null
									this.profile.id 	= null
									this.profile.roles 	= []
		  						})
		  				}else{

		  					Swal.fire({
		  						icon: 	'error',
		  						title: 	'Opss...',
		  						text: 	'Houve um erro ao concluir, contate o suporte'
		  					})
		  				}

		            })
		            .finally(() => {

		            	window.jQuery('.modal').modal('hide')  

		            	vm.FinishLoading()
		            })
			},
			New()
			{
				this.profile.name 	= null
				this.profile.id 	= null
				this.profile.roles 	= []

				window.jQuery('#modal-profile').modal('show')
			},
			Clone(profile)
			{
				this.profile 		= {
					name: 			profile.name
				}	

				this.profile.roles 	= profile.roles.map( profile => profile.id)

				window.jQuery('#modal-profile').modal('show')
			},
			Edit(profile)
			{
				this.profile 		= {
					id: 				profile.id,
					name: 				profile.name
				}

				this.profile.roles 	= profile.roles.map( profile => profile.id)

				window.jQuery('#modal-profile').modal('show')
			},
			SwitchClass(profile)
			{
				window.jQuery(`#switch${profile.id}`).prop("checked", profile.status)

				let status_label = profile.status ? 'ATIVO' : 'INATIVO'

				window.jQuery('label[for="switch' + profile.id + '"]').html(status_label)
			}
		},
		mounted()
		{
			this.GetProfiles()
			this.GetRoles()
		}
	}
</script>

<style lang="css" scoped>
</style>