<template>
    <div>
        <p class="mt-5 col-12 text-center text-danger">
            Aqui você irá editar a ordem de exibição e os detalhes de cada item do menu do Instant.
        </p>
        <div class="container-large pt-md-2">
            <div class="col-auto pb-3 pt-4 pt-md-0">
                <div class="row justify-content-between align-items-center px-2">
                    <div class="col-auto"
                        :class="edit ? 'pr-0' : ''">
                        <button 
                            class="btn mr-3 btn-sm"
                            :class="edit ? 'btn-danger' : 'btn-light'"
                            @click="EditingMenu"
                            style="border-radius: 16px; width: 60px;" 
                        >
                            <i 
                                class="far"
                                :class="edit ? 'fa-times fa-lg' : 'fa-pencil-alt'"
                            ></i>
                        </button>
                        <button
                            v-if="edit" 
                            class="btn btn-sm btn-success"
                            @click="SaveCustom(custom_menu)"
                            style="border-radius: 16px; width: 60px;"
                        >
                            <i class="fal fa-check fa-lg"></i>
                        </button>
                    </div>
                    
                    <div class="col-auto">
                        <button 
                            class="btn btn-outline-primary py-3"
                            @click="OpenModal()"
                        >
                            Novo
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="!edit" class="form-row pb-5 justify-content-start">
                <div 
                    v-for="menu in custom_menu"
                    :key="menu.role"
                    class="mb-2 mt-1 col-6 col-md-3 col-lg-2 col-xl-auto"
                >
                    <button 
                        class="box w-100 icons-card primary-hover mw-135px rounded-md"
                        @click="OpenModal(menu.id)"
                    >
                        <div class="card-body text-center position-absolute ps-0 d-flex align-items-center justify-content-center">
                            <div class="form-row h-100">
                                <div class="col-12 align-self-center">
                                    <i 
                                        :class="`${menu.icon} fal font-32 text-theme`"	
                                    >								
                                    </i>
                                </div>
                                <div class="col-12 align-self-start">
                                    <h5 
                                        class="m-0 font-16" 
                                    >
                                        {{ menu.text }}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </button>
                </div>
            </div>

            <draggable v-if="edit" v-model="custom_menu">
                <transition-group 
                    name="flip-list"
                    tag="div"
                    class="form-row pb-5 justify-content-start"
                >
                    <div 
                        v-for="menu in custom_menu"
                        :key="menu.role"
                        class="mb-2 mt-1 col-6 col-md-3 col-lg-2 col-xl-auto"
                    >
                        <button 
                        class="box w-100 icons-card primary-hover mw-135px rounded-md"
                        @click="OpenModal(menu.id)"
                    >
                            <div class="card-body text-center position-absolute ps-0 d-flex align-items-center justify-content-center">
                                <div class="form-row h-100">
                                    <div class="col-12 align-self-center">
                                        <i 
                                            :class="`${menu.icon} fal font-32 text-theme`"	
                                        >								
                                        </i>
                                    </div>
                                    <div class="col-12 align-self-start">
                                        <h5 
                                            class="m-0 font-16" 
                                        >
                                            {{ menu.text }}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                </transition-group>
            </draggable>
        </div>

        <div class="modal fade" id="modal-menu-item">
			<div class="modal-dialog modal-dialog d-flex justify-content-center align-items-center"
				@keydown.esc="CloseModal()">
				<div class="modal-content p-3 d-flex">
					<div class="align-self-end">
						<button
							id="close-modal"
							class="btn btn-light btn-sm m-2"
							@click="CloseModal"
						>
							<i class="fal fa-times"></i>
						</button>
					</div>
					<div class="modal-header mb-4">
						<h4 class="font-20 m-0 text-center">
							{{ edit_item ? 'Novo' : 'Alteração do'}} item
						</h4>
					</div>
					<div class="modal-body d-flex flex-column align-items-center justify-content-center">
						<div class="form col-10">
                            <div class="form-group">
                                <label
                                    for="text"
                                    class="font-18">
                                    Texto
                                </label>
                                <input
                                    id="text"
                                    type="text"
                                    class="form-control"
                                    v-model="item.text"
                                    placeholder="Ex: Relatórios"
                                >
                            </div>
                            <div class="form-group position-relative">
                                <label
                                    for="icon"
                                    class="font-18">
                                    Ícone
                                </label>
                                <input
                                    id="icon"
                                    type="text"
                                    class="form-control"
                                    v-model="item.icon"
                                    placeholder="Ex: fa-address-book"
                                >
                                <a class="ml-3 mt-2 text-primary" href="https://fontawesome.com/icons?d=gallery&p=2" target="_blank">fontawesome.com</a>
                                <div class="position-absolute icon-fa">
                                    <i v-if="item.icon"
                                        class="text-primary fal"
                                        :class="`${item.icon}`"></i>
                                    <i v-else class="text-primary fal fa-address-book"></i>
                                </div>
                            </div>
                            <div class="form-group">
                                <label
                                    for="route"
                                    class="font-18">
                                    Rota
                                </label>
                                <input
                                    id="route"
                                    type="text"
                                    class="form-control"
                                    v-model="item.route"
                                    placeholder="Ex: Schedules"
                                >
                            </div>
                            <div class="form-group">
                                <label
                                    for="role"
                                    class="font-18">
                                    Role
                                </label>
                                <input
                                    id="role"
                                    type="text"
                                    class="form-control"
                                    v-model="item.role"
                                    :placeholder="`A role atual é '${higher_role}'`"
                                >
                            </div>
                            <div class="form-group d-flex align-items-center">
                                <label
                                    for="force"
                                    class="font-18"
                                    >
                                    Forçar?
                                </label>
                                <input
                                    id="force"
                                    type="checkbox"
                                    class="ml-3"
                                    v-model="item.force"
                                >
                            </div>
                        </div>
					</div>
					<div class="modal-footer p-1 d-flex"
                        :class="edit_item ? 'justify-content-between' : 'justify-content-end'">
                        <button v-if="edit_item"
                            class="btn mt-2 mb-0 mx-3 btn-danger"
                            @click="DeleteMenuItem">
                            deletar
                        </button>
						<button 
							id="save-menu-item"
							class="btn mt-2 mb-0 mx-3 btn-primary"
							@click="SaveMenuItem">
							salvar
						</button>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
const _ = require('lodash')
export default {
    name:   'Menus',
    components: {
        draggable
    },
    data() {
        return {
            edit:           false,
            edit_item:      false,
            item:           {
                text:           '',
                icon:           '',
                role:           null,
                route:          '',
                force:          false
            },
            menu:           [],
            custom_menu:    [],
            modal_opened:   false,
        }
    },
    computed: {
        higher_role()
        {
            if(this.menu)
            {
                let higher = 0
                this.menu.forEach(m => {
                    if(m.role > higher)
                    {
                        higher = m.role
                    }
                })

                return higher
            }

            return null
        },
        default_order() {
            if(this.menu) {
                let order = 0

                this.menu.forEach(m => {
                    if(m.order > order) {
                        order = m.order
                    }
                })

                order += 1

                return order
            }

            return null
        }
    },
    methods: {
        ...mapActions('system', [
            'StartLoading', 'FinishLoading'
        ]),
        async GetMenuItems()
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('post', '/get-menu-items')
                .then(({data}) => {
                    if(data.response)
                    {
                        vm.menu         = _.orderBy(data.menu_items, 'order', 'asc')
                        vm.custom_menu  = vm.menu

                        return
                    }
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        async SetMenuOrder(val)
        {
            const vm = this

            vm.StartLoading()

            return await window.api.call('post', '/save-menu-order', {
                menu: val 
            })
                .then(({data}) => {
                    if(data.response)
                    {
                        Swal.fire({
                            icon:       'success',
                            title:      'Ordem do menu salva com sucesso.',
                            timer:      4000,
                            toast:      true,
                            position:   'top',
                            showConfirmButton: false,
                            timerProgressBar: true
                        })

                        return
                    }

                    Swal.fire({
                        icon:       'error',
                        title:      'Eita!',
                        text:       data.message,
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        EditingMenu()
        {
            this.edit = !this.edit

            // Cancel
            if(!this.edit) 
            {
                this.custom_menu = this.menu
            }
        },
        SaveCustom(val)
        {
            this.edit = false

            const menu_ordered = val.map((m, i) => {
                return { 
                    order: i + 1,
                    id: m.id
                }
            })

            this.SetMenuOrder(menu_ordered)
        },
        async SaveMenuItem()
        {
            const vm = this

            vm.StartLoading()

            vm.item.role = parseInt(vm.item.role)

            return await window.api.call('post', '/save-menu-item', {
                ...vm.item,
                default_order: vm.edit_item ? vm.item.order : vm.default_order
            })
                .then(async ({data}) => {
                    if(data.response)
                    {
                        Swal.fire({
                            icon:       'success',
                            title:      'Item salvo com sucesso.',
                            timer:      4000,
                            toast:      true,
                            position:   'top',
                            showConfirmButton: false,
                            timerProgressBar: true
                        })

                        await vm.GetMenuItems()

                        vm.CloseModal()

                        return
                    }

                    Swal.fire({
                        icon:       'error',
                        title:      'Eita!',
                        text:       data.message,
                    })
                })
                .finally(() => {
                    vm.FinishLoading()
                })
        },
        async DeleteMenuItem()
        {
            const vm = this

            Swal.fire({
                text:                   'Deseja realmente excluir o item?',
                title:                  'Atenção!',
                icon:                   'warning',
                showCancelButton:       true,
                confirmButtonText:      'Sim, excluir',
                cancelButtonText:       'Não, cancelar'
            })
                .then(async (result) => {
                    if(result.isConfirmed)
                    {
                        vm.StartLoading()

                        return await window.api.call('post', '/delete-menu-item', {
                            id: vm.item.id
                        })
                            .then(async ({data}) => {
                                if(data.response)
                                {
                                    Swal.fire({
                                        icon:       'success',
                                        title:      'Item excluído com sucesso.',
                                        timer:      4000,
                                        toast:      true,
                                        position:   'top',
                                        showConfirmButton: false,
                                        timerProgressBar: true
                                    })

                                    await vm.GetMenuItems()
                                    
                                    vm.CloseModal()

                                    return
                                }

                                Swal.fire({
                                    icon:       'error',
                                    title:      'Eita!',
                                    text:       data.message,
                                })
                            })
                            .finally(() => {
                                vm.FinishLoading()
                            })
                    }
                })
        },
        OpenModal(menu_id)
        {
            window.jQuery('#modal-menu-item').modal('show')

            this.modal_opened = true

            if(menu_id)
            {
                this.edit_item = true

                this.item = this.menu.filter(m => m.id == menu_id)[0]
            }

            window.jQuery('body').addClass('modal-open')
        },
        CloseModal()
        {
            window.jQuery('#modal-menu-item').modal('hide')

            this.modal_opened = false

            this.edit_item = false

            this.item = {}

            window.jQuery('body').removeClass('modal-open')
        },
    },
    beforeMount()
    {
        this.GetMenuItems()
    }
}
</script>

<style lang="css" scoped>
.text-primary
{
    color: var(--primary) !important;
}

.mw-135px
{
    min-width: 135px;
}

h5
{
    white-space: break-spaces;
}

.flip-list-move {
    transition: transform .3s;
}

.rounded-md 
{
    border-radius: 1.4rem !important;
}

.icon-fa
{
    right: -45px;
    top: 30px;
    font-size: 2rem;
}
</style>